<template>
  <div>
    <input
      class="cf-input"
      type="text"
      name="code_sms"
      v-for="(val, index) of value"
      :key="`code-input_${index}`"
      :ref="`code-input_${index}`"
      @keyup="next($event, index)"
      @click="$event.target.select()"
      @paste.prevent="paste"
      v-model="value[index]"
    />
  </div>
</template>

<script>
export default {
  name: 'ConfirmCodeInputFirst',
  props: {

  },
  data() {
    return {
      value: ['', '', '', '', '', '']
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit('input', this.fullNumber);
      }
    }
  },
  computed: {
    fullNumber() {
      return this.value.join('');
    }
  },
  methods: {
    next(event, index) {
      const nextInput = this.$refs[`code-input_${index + 1}`];
      const num = event.target.value;
      if (nextInput && nextInput.length) {
        this.value[index] = num.length === 1 ? num : '';
        nextInput[0].focus();
        nextInput[0].select();
      } else {
        event.preventDefault();
      }
    },
    paste(event) {
      let paste = (event.clipboardData || window.clipboardData).getData('text');
      this.value = this.prepareNumber(paste);
    },
    prepareNumber(num) {
      const number = [...num].slice(0, 4);
      let res = [];
      res = number.map(el => {
        if (el.match(/\d/) && el.length === 1) {
          return el;
        } else {
          return '0';
        }
      });
      return res;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
