<template>
  <div class="fs full-screen">
    <div class="fs-content fs-content--shadow">
      <h1 class="fs-title">Подтвердите Ваш номер телефона</h1>
      <p class="fs-desc">
        Введите код, который мы <br />
        отправили на номер:
      </p>
      <p>{{ $route.query.phone }}</p>
      <form class="cf confirm-form">
        <ConfirmCodeInput v-model="confirmCode" />
      </form>

      <button v-if="wait === 0" @click="reSend" class="link link--block link--blue">Отправить код еще раз</button>

      <template v-else>
        <p class="fs-desc">
          Отправить код повторно через<br /><span>{{ wait }}</span> секунд
        </p>
      </template>

      <div class="fs-btn-wrapper">
        <transition name="fade">
          <button :disabled="loading" v-if="confirmCode.length >= 6" class="fs-btn btn btn--blue" @click="confirm">
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
            <template v-else> Подтвердить код </template>
          </button>
        </transition>

        <router-link :to="{ name: this.$routeName.RESTORE_ACCESS }" class="link link--block link--blue">
          Я ошибся с номером
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmCodeInput from '@component/UI/Form/ConfirmCodeInput';
export default {
  name: 'ConfirmPhone',
  components: {
    ConfirmCodeInput,
  },
  data() {
    return {
      confirmCode: '',
      wait: 0,
      timer: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      isAuth: state => state.auth.isAuth
    })
  },
  mounted() {
    this.handleTimer();
  },
  methods: {
    async confirm() {
      try {
        this.loading = true;
        const token = await RequestManager.Auth.authorize({ mobile: this.$route.query.phone, token: this.confirmCode });
        await this.$store.dispatch('auth/auth', token);
        await this.$store.dispatch('user/getUser');

        if (this.isAuth) {

          // if (this.user.is_registered) {
            this.$getFirebaseMessagingToken();
         //  }

          const inviteId = this.$route.query?.invite_id;

          if (inviteId) {
            await RequestManager.Invite.unregisteredUserAcceptInvite(inviteId);
          }

          if (!this.user.first_name) {
            if (!this.user.email) {
              this.$router.push({name: this.$routeName.CONFIRM_EMAIL});
              return;
            }
            this.$router.push({ name: this.$routeName.ASSISTANCE });
          } else {
            this.$router.push({ name: this.$routeName.APPLICATIONS });
          }
        } else {
          this.$modal.show('ErrorMessage');
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async reSend() {
      const { phone } = this.$route.query;
      try {
        await RequestManager.Auth.confirmPhone(phone);
      } catch (err) {
        this.$modal.show('ErrorMessage');
      }
      this.handleTimer();
    },
    handleTimer() {
      this.wait = 60;
      this.canResend = false;
      this.timer = setInterval(() => {
        this.wait--;
        if (this.wait <= 0) {
          clearInterval(this.timer);
          this.canResend = true;
          this.timer = null;
        }
      }, 1000);
    },
  }
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh;
  min-height: 800px;
  .fs-title {
    margin-bottom: 15px;
  }

  .fs-desc {
    margin-bottom: 0;
  }

  .fs-btn {
    margin-bottom: 24px;
  }
}

.link {
  text-align: center;
  width: 100%;
}
</style>
